.tolq-snackbar {
  @apply flex w-full justify-between border-0 border-l-[3px] border-l-tolq-blue-200 px-2 py-3;
}

.snackbar--neutral {
  @apply border border-solid border-tertiary-500 bg-white;
}

.snackbar--info {
  @apply bg-tertiary-600;

  & path {
    @apply fill-gradient-royal-blue;
  }
}

.snackbar--warning {
  @apply bg-primary-orange-1200;

  & path {
    @apply fill-primary-orange-100;
  }
}

.snackbar--danger {
  @apply bg-primary-red-300;

  & path {
    @apply fill-primary-red-100;
  }
}

.snackbar--success {
  @apply bg-primary-green-300;

  & path {
    @apply fill-primary-green-100;
  }
}
